import { productFilters } from '../../views/viola/basicConfig'

const productReducer = (state = { filters: productFilters }, { type, payload }) => {
  switch (type) {
    case 'UPDATE_PRODUCTS':
      let newState = { ...state }
      newState.filters = { ...newState.filters, ...payload }
      return newState
    default:
      return state
  }
}

export default productReducer;