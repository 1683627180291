import { apiConfig } from 'src/config';

import axios from 'axios';

const apiClient = axios.create({
  baseURL: apiConfig.baseURL,
  withCredentials: true,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  },
});

/*
,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  }
 */

export default apiClient;

export { apiClient };
