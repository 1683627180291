import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  logo: {
    'height': '55px',
    'marginTop': '5px',
    '@media(max-width:1279px)': {
      display: 'none'
    }
  },
  title: {
    display: 'none',
    '@media(max-width:1279px)': {
      display: 'flex'
    },
    height: 40,
    marginTop: 5,
    justifyContent: 'space-between',
    alignItems: 'flex-end',
  },
  line: {
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.text.secondary,
    width: 35,
    margin: '15px 10px',
  },
  text: {
    fontSize: 28,
    fontWeight: 500,
    textTransform: 'uppercase',
    letterSpacing: 4,
    color: theme.palette.text.secondary,
    fontFamily: "Century Gothic, sans-serif",
    textDecoration: 'none',
  }
}))


const Logo = (props) => {
  const classes = useStyles();
  return (
    <>
      <img
        alt="Logo"
        src="/static/viola.png"
        className={classes.logo}
        {...props}
      />
      <div className={classes.title}>
        <div className={classes.line}></div>
        <div className={classes.text}>menu</div>
        <div className={classes.line}></div>
      </div>
    </>
  );
}

export default Logo;
