import _ from 'lodash';
import {
  colors,
  createMuiTheme,
  responsiveFontSizes
} from '@material-ui/core';
import { THEMES } from 'src/constants';
import { softShadows, strongShadows } from './shadows';
import typography from './typography';

const baseOptions = {
  direction: 'ltr',
  typography,
  overrides: {
    MuiLinearProgress: {
      root: {
        borderRadius: 3,
        overflow: 'hidden'
      }
    },
    MuiListItemIcon: {
      root: {
        minWidth: 32
      }
    },
    MuiChip: {
      root: {
        backgroundColor: 'rgba(0,0,0,0.075)'
      }
    }
  }
};

const themesOptions = [
  {
    name: THEMES.LIGHT,
    overrides: {
      MuiInputBase: {
        input: {
          '&::placeholder': {
            opacity: 1,
            color: colors.blueGrey[600],
          }
        }
      },
      MuiButton: {
        root: {
          '&$disabled': {
            backgroundImage: 'none',
          }
        },
        contained: {
          marginLeft: 10,
          borderRadius: 30,
        },
        containedPrimary: {
          color: colors.common.white,
          backgroundColor: '#335ABA',
          backgroundImage: 'linear-gradient(to right, #3949AB, #17A8FA50)',
          '&:hover': {
            backgroundColor: '#335ABA',
            backgroundImage: 'none',
            boxShadow: `1px 1px 8px #bbb`,
          }
        },
        containedSecondary: {
          color: colors.common.white,
          backgroundColor: '#A00000',
          backgroundImage: 'linear-gradient(to right, #A00000, #FF0000DD)',
          '&:hover': {
            backgroundColor: '#A00000',
            backgroundImage: 'none',
            boxShadow: `1px 1px 8px #bbb`,
          }
        },
      }
    },
    palette: {
      type: 'light',
      action: {
        active: colors.blueGrey[600]
      },
      background: {
        default: colors.common.white,
        dark: '#f4f6f8',
        paper: colors.common.white,
        blue: colors.blueGrey[50],
        indigo: colors.indigo[50]
      },
      primary: {
        // main: colors.indigo[600],
        main: '#335ABA',
        mainOpaque: '#335ABA10',
        mainGradient: 'linear-gradient(to right, #3949AB, #17A8FA50)'
      },
      secondary: {
        main: '#5850EC'
      },
      red: {
        main: '#A00000',
        mainGradient: 'linear-gradient(to right, #A00000, #FF0000DD)'
      },
      green: {
        main: '#00A000',
        mainGradient: 'linear-gradient(to right, #00A000, #00FF0090)',
        mainOpaque: '#00FF0080',
        mainSuperOpaque: '#00FF0040',
      },
      text: {
        primary: colors.blueGrey[900],
        secondary: colors.blueGrey[600]
      }
    },
    shadows: softShadows,
    shape: {
      borderRadius: 0,
    },
    props: {
      MuiCard: {
        elevation: 6,
      },
      MuiButton: {
        primary: {}
      }
    }
  },
  {
    name: THEMES.ONE_DARK,
    palette: {
      type: 'dark',
      action: {
        active: 'rgba(255, 255, 255, 0.54)',
        hover: 'rgba(255, 255, 255, 0.04)',
        selected: 'rgba(255, 255, 255, 0.08)',
        disabled: 'rgba(255, 255, 255, 0.26)',
        disabledBackground: 'rgba(255, 255, 255, 0.12)',
        focus: 'rgba(255, 255, 255, 0.12)'
      },
      background: {
        default: '#282C34',
        dark: '#1c2025',
        paper: '#282C34'
      },
      primary: {
        main: '#8a85ff'
      },
      secondary: {
        main: '#8a85ff'
      },
      text: {
        primary: '#e6e5e8',
        secondary: '#adb0bb'
      }
    },
    shadows: strongShadows
  },
  {
    name: THEMES.UNICORN,
    palette: {
      type: 'dark',
      action: {
        active: 'rgba(255, 255, 255, 0.54)',
        hover: 'rgba(255, 255, 255, 0.04)',
        selected: 'rgba(255, 255, 255, 0.08)',
        disabled: 'rgba(255, 255, 255, 0.26)',
        disabledBackground: 'rgba(255, 255, 255, 0.12)',
        focus: 'rgba(255, 255, 255, 0.12)'
      },
      background: {
        default: '#2a2d3d',
        dark: '#222431',
        paper: '#2a2d3d'
      },
      primary: {
        main: '#a67dff'
      },
      secondary: {
        main: '#a67dff'
      },
      text: {
        primary: '#f6f5f8',
        secondary: '#9699a4'
      }
    },
    shadows: strongShadows
  }
];

export const createTheme = (config = {}) => {
  let themeOptions = themesOptions.find((theme) => theme.name === config.theme);

  if (!themeOptions) {
    console.warn(new Error(`The theme ${config.theme} is not valid`));
    [themeOptions] = themesOptions;
  }

  let theme = createMuiTheme(
    _.merge(
      {},
      baseOptions,
      themeOptions,
      { direction: config.direction }
    ),
    config.language
  );

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
}
