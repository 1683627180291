import { format, subDays } from 'date-fns';

const baseConfig = {
  filters: {
    perPage: 10,
    currentDate: format(new Date(), 'yyyy-MM-dd'),
    twoWeeksAgo: format(subDays(new Date(), 14), 'yyyy-MM-dd')
  },
};

export const returnFilters = {
  page: 0,
  perPage: baseConfig.filters.perPage,
  status: '',
  shop: null,
  product: '',
  sorting: '',
  dateFilters: {
    start: baseConfig.filters.currentDate,
    end: baseConfig.filters.currentDate,
  }
}

export const productFilters = {
  page: 1,
  perPage: 12,
  name: '',
  index: '',
  symbol: '',
  sorting: '-magad-index'
}

export const statsFilters = {
  start: baseConfig.filters.twoWeeksAgo,
  end: baseConfig.filters.currentDate
}

export const orderProductFilters = {
  page: 0,
  limit: baseConfig.filters.perPage,
  phrase: '',
  inStock: true,
  sorting: 'index',
}

export const orderFilters = {
  shop: null,
  order: 0,
  sorting: 'number',
  shipmentable: true,
  status: '',
  lineStatus: '',
  barcode: '',
  perPage: baseConfig.filters.perPage,
  page: 0,
  dates: {
    start: baseConfig.filters.currentDate,
    end: baseConfig.filters.currentDate,
  }
}

export const categoryFilters = {
  name: '',
  page: 0,
  limit: baseConfig.filters.perPage,
}

export default baseConfig;