import { categoryFilters } from '../../views/viola/basicConfig';

const categoryReducer = (state = { filters: categoryFilters }, { type, payload }) => {
  switch (type) {
    case 'UPDATE_CATEGORIES':
      let newState = { ...state }
      newState.filters = { ...newState.filters, ...payload }
      return newState
    default:
      return state
  }
}

export default categoryReducer;