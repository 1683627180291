import React from 'react';
import clsx from 'clsx';
import {
    Box,
    Button,
    Paper,
    Typography,
    makeStyles
} from '@material-ui/core';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';


const useStyles = makeStyles(theme => ({
    root: {},
    backdrop: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        zIndex: 2000,

        backgroundColor: 'rgba(0,0,0,.5)',
        backdropFilter: 'blur(3px)',

        display: 'grid',
        placeItems: 'center',
    },
    modal: {
        minHeight: 350,
        padding: 40,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: '#fff',
        boxShadow: '12px 12px 45px #444'
    },
    icon: {
        width: 80,
        height: 80,
    },
    message: {
        fontSize: 18,
        textAlign: 'center',
        padding: '0 20%',
        color: '#555',
        '@media(max-width:500px)': {
            padding: 0,
        }
    },
    wrapper: {
        padding: 2,
        maxWidth: '90%',
    },
    primarywrapper: {
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.primary.main,
        backgroundImage: theme.palette.primary.mainGradient
    },
    secondarywrapper: {
        backgroundColor: theme.palette.secondary.main,
        backgroundImage: theme.palette.secondary.mainGradient
    },
    btn: {
        borderRadius: 0,
        marginLeft: 0,
    }
}))

const Modal = ({ message, btnMsg, clicked, bgClicked, level }) => {
    const classes = useStyles();

    return (
        <div className={classes.backdrop} onClick={bgClicked}>
            <Paper className={clsx(classes.wrapper, classes[level + 'wrapper'])} elevation={8}>
                <Box className={classes.modal}>
                    <ErrorOutlineIcon fontSize="large" className={classes.icon} />
                    <Typography
                        variant="body1"
                        className={classes.message}
                    >{message}</Typography>
                    <Button
                        onClick={clicked}
                        className={classes.btn}
                        color={level}
                        variant="contained"
                    >
                        {btnMsg}
                    </Button>
                </Box>
            </Paper>

        </div >
    );
}

export default Modal;