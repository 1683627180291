import { combineReducers } from '@reduxjs/toolkit';
import productReducer from './reducers/productReducer';
import categoryReducer from './reducers/categoryReducer';
import returnsReducer from './reducers/returnsReducer';
import statsReducer from './reducers/statsReducer';
import ordersReducer from './reducers/ordersReducer';
import orderProductsReducer from './reducers/orderProductsReducer';
// import { reducer as calendarReducer } from 'src/slices/calendar';
// import { reducer as chatReducer } from 'src/slices/chat';
// import { reducer as formReducer } from 'redux-form';
// import { reducer as kanbanReducer } from 'src/slices/kanban';
// import { reducer as mailReducer } from 'src/slices/mail';
// import { reducer as notificationReducer } from 'src/slices/notification';

const rootReducer = combineReducers({
  // calendar: calendarReducer,
  // chat: chatReducer,
  // form: formReducer,
  // kanban: kanbanReducer,
  // mail: mailReducer,
  // notifications: notificationReducer
  products: productReducer,
  categories: categoryReducer,
  returns: returnsReducer,
  stats: statsReducer,
  orders: ordersReducer,
  orderProducts: orderProductsReducer,
});

export default rootReducer;
