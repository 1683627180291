import React, {
  Suspense,
  Fragment,
  lazy
} from 'react';
import {
  Switch,
  Redirect,
  Route
} from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
// import DocsLayout from 'src/layouts/DocsLayout';
// import MainLayout from 'src/layouts/MainLayout';
// import HomeView from 'src/views/home/HomeView';
import LoadingScreen from 'src/components/LoadingScreen';
import AuthGuard from 'src/components/AuthGuard';
import GuestGuard from 'src/components/GuestGuard';

export const renderRoutes = (routes = []) => (
  <Suspense fallback={<LoadingScreen />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={(props) => (
              <Guard>
                <Layout>
                  {route.routes
                    ? renderRoutes(route.routes)
                    : <Component {...props} />}
                </Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
);

const routes = [
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('src/views/errors/NotFoundViewNew'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/login',
    component: lazy(() => import('src/views/auth/LoginView'))
  },
  {
    path: '*',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/app/dashboard',
        component: lazy(() => import('src/views/viola/home/HomeView'))
      },
      {
        exact: true,
        path: '/app/orders',
        component: lazy(() => import('src/views/viola/order/OrderListView'))
      },
      {
        exact: true,
        path: '/app/orders/products',
        component: lazy(() => import('src/views/viola/order/ProductListView'))
      },
      {
        exact: true,
        path: '/app/orders/stats',
        component: lazy(() => import('src/views/viola/order/OrderStatsView'))
      },
      {
        exact: true,
        path: '/app/orders/:orderId',
        component: lazy(() => import('src/views/viola/order/OrderDetailsView'))
      },
      {
        exact: true,
        path: '/app/products/list',
        component: lazy(() => import('src/views/viola/product/ProductListView'))
      },
      {
        exact: true,
        path: '/app/products/single/:id',
        component: lazy(() => import('src/views/viola/product/ProductDetailsView'))
      },
      {
        exact: true,
        path: '/app/products/categories',
        component: lazy(() => import('src/views/viola/product/CategoryListView'))
      },
      {
        exact: true,
        path: '/app/products/categories/:productId',
        component: lazy(() => import('src/views/viola/product/CategoryAttrView'))
      },
      {
        exact: true,
        path: '/app/returns/list',
        component: lazy(() => import('src/views/viola/return/ReturnListView'))
      },
      {
        exact: true,
        path: '/app/auto/gates',
        component: lazy(() => import('src/views/viola/automation/GatesView'))
      },
      {
        exact: true,
        path: '/app/auto/gates-table',
        component: lazy(() => import('src/views/viola/automation/GatesTableView'))
      },
      {
        exact: true,
        path: '/app/auto/gates-settings',
        component: lazy(() => import('src/views/viola/automation/GatesSettings'))
      },
      {
        exact: true,
        path: '/app/barcode/scanner',
        component: lazy(() => import('src/views/viola/barcode'))
      },
      {
        // this is a testing route for checking different views from the template
        exact: true,
        path: '/test',
        component: lazy(() => import('src/views/product/ProductListView'))
      },
      {
        exact: true,
        path: '/app',
        component: () => <Redirect to="/app/dashboard" />
      },
      {
        exact: true,
        path: '/',
        component: () => <Redirect to="/app/dashboard" />
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  },
];

export default routes;
