import { statsFilters } from '../../views/viola/basicConfig'

const statsReducer = (state = { filters: statsFilters }, { type, payload }) => {
  switch (type) {
    case 'UPDATE_STATS':
      let newState = { ...state }
      newState.filters = { ...newState.filters, ...payload }
      return newState
    default:
      return state
  }
}

export default statsReducer;