import { returnFilters } from '../../views/viola/basicConfig'

const returnsReducer = (state = { filters: returnFilters }, { type, payload }) => {
  switch (type) {
    case 'UPDATE_RETURNS':
      let newState = { ...state }
      newState.filters = { ...newState.filters, ...payload }
      return newState
    default:
      return state
  }
}

export default returnsReducer;