import { orderProductFilters } from '../../views/viola/basicConfig';

const orderProductsReducer = (state = { filters: orderProductFilters }, { type, payload }) => {
  switch (type) {
    case 'UPDATE_ORDER_PRODUCTS':
      let newState = { ...state }
      newState.filters = { ...newState.filters, ...payload }
      return newState
    default:
      return state
  }
}

export default orderProductsReducer;