import React, {
  createContext,
  useEffect,
  useReducer
} from 'react';

import SplashScreen from 'src/components/SplashScreen';

// import jwtDecode from 'jwt-decode';
import axios from 'src/utils/axios';

import apiClient from 'src/api/api';

const initialAuthState = {
  isAuthenticated: false,
  isInitialised: false,
  user: null
};

/*const isValidToken = (accessToken) => {
  if (!accessToken) {
    return false;
  }

  const decoded = jwtDecode(accessToken);
  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};*/

/*
const setSession = (accessToken) => {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    localStorage.removeItem('accessToken');
    delete axios.defaults.headers.common.Authorization;
  }
};
*/

const reducer = (state, action) => {
  switch (action.type) {
    case 'INITIALISE': {
      const { isAuthenticated, user } = action.payload;

      return {
        ...state,
        isAuthenticated,
        isInitialised: true,
        user
      };
    }
    case 'LOGIN': {
      const { user } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        user
      };
    }
    case 'LOGOUT': {
      return {
        ...state,
        isAuthenticated: false,
        user: null
      };
    }
    case 'REGISTER': {
      const { user } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        user
      };
    }
    default: {
      return { ...state };
    }
  }
};

const AuthContext = createContext({
  ...initialAuthState,
  method: 'COOKIE',
  login: () => Promise.resolve(),
  logout: () => {
  },
  register: () => Promise.resolve()
});

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialAuthState);

  const checkUser = async () => {
    const response = await apiClient.get('api/me');

    const user = response.data;

    dispatch({
      type: 'INITIALIZE',
      payload: {
        user,
        isAuthenticated: Boolean(user)
      }
    })
  }

  const login = async (email, password) => {

    await apiClient.get('sanctum/csrf-cookie');
    await apiClient.post('login', {
      email: email,
      password: password
    });

    const response = await apiClient.get('api/me');
    // console.log(response);


    const user = response.data;


    dispatch({
      type: 'LOGIN',
      payload: {
        user
      }
    });
  };

  const logout = async () => {
    await apiClient.post('logout');
    dispatch({ type: 'LOGOUT' });
  };

  const register = async (email, name, password) => {
    const response = await axios.post('/api/account/register', {
      email,
      name,
      password
    });
    const { accessToken, user } = response.data;

    window.localStorage.setItem('accessToken', accessToken);

    dispatch({
      type: 'REGISTER',
      payload: {
        user
      }
    });
  };

  useEffect(() => {

    const initialise = async () => {

      apiClient.get('/api/me')
        .then(response => {

          const user = response.data;

          dispatch({
            type: 'INITIALISE',
            payload: {
              isAuthenticated: true,
              user
            }
          });
        })
        .catch(() => {
          dispatch({
            type: 'INITIALISE',
            payload: {
              isAuthenticated: false,
              user: null
            }
          });
        });
    };

    initialise();
  }, []);

  if (!state.isInitialised) {
    return <SplashScreen />;
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'COOKIE',
        login,
        logout,
        register,
        checkUser
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
