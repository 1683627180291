import React, { useEffect, Fragment, useState } from 'react';
import useAuth from 'src/hooks/useAuth';
import apiClient from '../../../api/api'
import Modal from '../UI/Modal'

// this context redirects user to login page when the login session expires

const ApiAuthProvider = (props) => {
  const { logout } = useAuth();

  const [error, setError] = useState('')

  useEffect(() => {
    const resInterceptor = apiClient.interceptors.response.use(res => res, err => {
      if (err.response?.status && err.response.status === 401) {
        setError('Twoja sesja zakończyła się, musisz zalogować się ponownie')
      }
      return Promise.reject(err)
    })
    return () => {
      apiClient.interceptors.response.eject(resInterceptor)
    }
  }, [])

  const backToLogin = () => {
    setError('');
    logout()
  }

  const logMessage = () => {
    console.log('Cannot close this modal otherwise, than through loggin out');
  }

  return (
    <Fragment>
      {error && <Modal message={error} btnMsg="Powrót do logowania" clicked={backToLogin} bgClicked={logMessage} level="primary" />}
      {props.children}
    </Fragment>
  )

}

export default ApiAuthProvider;